import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src3100744155/src/autobackup-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "tags"
    }}>{`Tags`}</h1>
    <h2 {...{
      "id": "enable-or-disable-tag-based-backup"
    }}>{`Enable or disable Tag-based Backup`}</h2>
    <p>{`You can enable/disable tag-based backup for your account in `}<strong parentName="p">{`Settings`}</strong>{`.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Enabling tag-based backup will override manually set up backups. It will not be possible to change backup configuration from the level of UI.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`If backup controlled by tags is enabled and tags applied to resource are correct then previously used configuration will be updated to this described in tags.
Otherwise, AB configuration won't be replaced.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`For the Route53 it is recommended to tag only one Hosted Zone with backup tags.
Tagging multiple Hosted Zones with different backup tags value will cause the error and Route 53 will not be backed up.`}</p>
    </blockquote>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Choose `}<strong parentName="p">{`Settings`}</strong>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Choose `}<strong parentName="p">{`Profile`}</strong>{` tab.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Use switch under `}<strong parentName="p">{`Backup controlled by Tags`}</strong>{` to enable or disable this option.`}</p>
        <p parentName="li"><img alt="Tags" src={require("../../assets/configure/tags/tags-based-configuration_5.png")} /></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Cancel or confirm your action in popup.`}</p>
        <p parentName="li"><img alt="Tags" src={require("../../assets/configure/tags/tags-based-configuration_6.png")} /></p>
      </li>
    </ol>
    <h2 {...{
      "id": "add-tag-based-backup-configuration"
    }}>{`Add Tag-based backup configuration`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Choose "Settings" from the navigation bar.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Choose "Tags" tab.`}</p>
        <p parentName="li"><img alt="Tags" src={require("../../assets/configure/tags/tags-based-configuration_1.png")} /></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`You can add tags in 3 categories:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Backup enabled tags`}</li>
          <li parentName="ul">{`Policy tags`}</li>
          <li parentName="ul">{`Setup tags`}</li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li">{`To add a new tag to any category you need to click "Add new tag" button below the column. Type tag value in new input field. You can add as many tags as you need.`}</p>
        <p parentName="li"><img alt="Tags" src={require("../../assets/configure/tags/tags-based-configuration_2.png")} /></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`When you type all needed tags, you can click "Save" button.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Click on "Save" button on the confirmation window.`}</p>
        <p parentName="li"><img alt="Tags" src={require("../../assets/configure/tags/tags-based-configuration_3.png")} /></p>
      </li>
    </ol>
    <h2 {...{
      "id": "available-tags-values"
    }}>{`Available tags values`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`To show a list of available values for tags you can click on the buttons:`}</p>
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`Show values`}</inlineCode>{` for Backup enabled tags,`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`Show policies`}</inlineCode>{` for Policy tags,`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`Show setups`}</inlineCode>{` for Setup tags`}</li>
        </ul>
        <p parentName="li"><img alt="Tags" src={require("../../assets/configure/tags/tags-based-configuration_1.png")} /></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Copy `}<inlineCode parentName="p">{`Tag value`}</inlineCode>{` by clicking on `}<inlineCode parentName="p">{`copy`}</inlineCode>{` button on the right side.
`}<img alt="Tags" src={require("../../assets/configure/tags/tags-based-configuration_4.png")} />{`
Now you can paste them (or identifiers of setups/policies) into your tag value in cloud provider console.`}</p>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      